import NewAxiosInstanceFunc from 'Service/NewAxiosInstance'
import { INewResponse } from 'Service/INewResponse'

export interface IPrivateEquityUser {
  IsSetPassword: boolean
  LoginErrCode: number
  Authorization: string // Token
  CompanyName: string
  CompanyPointsNum: number // 研选剩余点数
  Email: string
  EndDate: string
  Headimgurl: string
  Mobile: string
  OutboundCountryCode: string
  OutboundMobile: string
  PermissionName: string[]
  PermissionStatus: string
  StartDate: string
  UserCardEndDate: string // 畅读卡到期时间
  UserCardType: number //  畅读卡类型0:未开通、1日卡、2月卡
  UserId: number
  UserName: string
  HasPermission: number
  IsAuthor: boolean // 是否开通了专栏
  SpecialColumnId: number // 专栏ID
  IsImproveInformation: boolean // 专栏信息是否需要完善  为false的时候进行编辑
  InviteShareCode: string // 邀请码
  IsShowWxPaySet: boolean //是否开启了支付
}
export interface ILoginWechartRes {
  CompanyName: string
  HasPermission: number
  Headimgurl: string
  Mobile: string
  Permission: string
  RealName: string
  Token: string
}

export const Login2pService = {
  getVCode: (AreaNum: string, Mobile: string): INewResponse =>
    NewAxiosInstanceFunc({
      url: '/resource/getSmsCode',
      method: 'post',
      data: { AreaNum, Mobile }
    }),
  postLogin2p: (
    Mobile: string,
    VCode: string,
    LoginType?: string,
    Password?: string,
    Token?: string,
    InviteShareCode?: string
  ): INewResponse<IPrivateEquityUser> =>
    NewAxiosInstanceFunc({
      url: '/user/login',
      method: 'post',
      data: { Mobile, VCode, LoginType, Password, Token, InviteShareCode }
    }),
  postLoginResetPassword: (Mobile: string, VCode: string, Password?: string): INewResponse<IPrivateEquityUser> =>
    NewAxiosInstanceFunc({
      url: '/user/reset_pass',
      method: 'post',
      data: { Mobile, VCode, Password }
    }),
  getUserDetail: (token: string | undefined): INewResponse<IPrivateEquityUser> =>
    NewAxiosInstanceFunc({
      url: '/user/detail',
      method: 'get',
      headers: { Authorization: token }
    }),
  loginWechat: (Code: string, IsWechat: number): INewResponse<ILoginWechartRes> =>
    NewAxiosInstanceFunc({
      url: '/wechat/login',
      method: 'get',
      params: { Code, IsWechat }
    }),
  postWechatBindMobile: (Mobile: string, VCode: string): INewResponse =>
    NewAxiosInstanceFunc({
      url: '/wechat/bindMobile',
      method: 'post',
      data: { Mobile, VCode }
    }),
  postSetPassword: (Mobile: string, VCode: string, Password: string): INewResponse =>
    NewAxiosInstanceFunc({
      url: '/user/set_pass',
      method: 'post',
      data: { Mobile, VCode, Password }
    }),
  postUpdatePassword: (OldPassword: string, NewPassword: string): INewResponse =>
    NewAxiosInstanceFunc({
      url: '/user/update_pass',
      method: 'post',
      data: { OldPassword, NewPassword }
    })
}
