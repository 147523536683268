import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Result, Button } from 'antd'
import EditingImg from 'assets/editing.png'
import LoginImg from 'assets/login.png'
import EmptyImg from 'assets/empty.png'

import { useLogin2p } from 'Login2p/Login2pContext'
import 'styles/CommonGlobal.module.scss'

export const VmpNotfound: React.FC = () => {
  const login2p = useLogin2p()

  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，您访问的页面不存在~"
      extra={
        <Link to={`/${login2p.inviteCode ? '?invite_code=' + login2p.inviteCode : ''}`}>
          <Button type="primary" className="big-long-btn">
            返回首页
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNoAuthority: React.FC = () => {
  const login2p = useLogin2p()

  return (
    <Result
      status="403"
      title="401"
      subTitle="抱歉，您当前还没有获得该页面的访问权限哦~"
      extra={
        <Link to={`/${login2p.inviteCode ? '?invite_code=' + login2p.inviteCode : ''}`}>
          <Button type="primary" className="big-long-btn">
            返回首页
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpSeverError: React.FC = () => {
  const login2p = useLogin2p()

  return (
    <Result
      status="500"
      title="500"
      subTitle="当前网络不稳定，请稍后再试~"
      extra={
        <Link to={`/${login2p.inviteCode ? '?invite_code=' + login2p.inviteCode : ''}`}>
          <Button type="primary" className="big-long-btn">
            返回首页
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNotPublish: React.FC = () => {
  const login2p = useLogin2p()

  return (
    <Result
      status="warning"
      title="该报告/图表暂未发布"
      extra={
        <Link to={`/${login2p.inviteCode ? '?invite_code=' + login2p.inviteCode : ''}`}>
          <Button type="primary" className="big-long-btn">
            回到主页
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNoAuthentication: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <Result
      status="403"
      title="未登录"
      subTitle="您还没有登录哦~"
      extra={
        <Link to={`/login?${new URLSearchParams({ next: pathname }).toString()}`}>
          <Button type="primary" className="big-long-btn">
            登录
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}
export const WebNoAuthentication: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <Result
      icon={<img src={LoginImg} alt="未登录" style={{ maxWidth: '240px' }} />}
      title="未登录"
      subTitle="您还没有登录哦~"
      extra={
        <Link to={`/login2p?${new URLSearchParams({ next: pathname }).toString()}`}>
          <Button type="primary" className="big-long-btn">
            登录
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}
export const WebEditError: React.FC = () => {
  const login2p = useLogin2p()

  return (
    <Result
      icon={<img src={EditingImg} alt="编辑中" style={{ maxWidth: '507px' }} />}
      subTitle="正在努力编辑中，请耐心等待..."
      extra={
        <Link to={`/${login2p.inviteCode ? '?invite_code=' + login2p.inviteCode : ''}`}>
          <Button type="primary" ghost className="big-long-btn">
            返回首页
          </Button>
        </Link>
      }
      style={{ margin: '5% auto' }}
    />
  )
}
interface IWebNotPublishProps {
  title?: string // 文案
}

export const WebNotPublish: React.FC<IWebNotPublishProps> = props => {
  return (
    <Result
      icon={<img src={EmptyImg} alt="编辑中" style={{ maxWidth: '252px' }} />}
      title={<span style={{ color: '#999', fontSize: '18px' }}>{props?.title || '报告加急审核中，请耐心等待～'}</span>}
      style={{ margin: '5% auto' }}
    />
  )
}
