import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Link, useLocation, useHistory } from 'react-router-dom'

// eslint-disable-next-line
import 'swiper/swiper-bundle.css'

// import { Result, Button } from 'antd'

// import { useSearchParams } from 'hooks/useSearchParams'
// import Home from 'Home'

import { useLogin2p } from 'Login2p/Login2pContext'
import { VmpNotfound } from 'components/Empty'
import { getQueryString } from 'utils/getQueryString'
import './styles/CommonGlobal.module.scss'

const Login2p = React.lazy(() => import('./Login2p/Login2p'))
const Login2pWechatLanding = React.lazy(() => import('./Login2p/Login2pWechatLanding'))
const NewIndex = React.lazy(() => import('./NewIndex'))

const Vmp: React.FC = () => {
  const login2p = useLogin2p()
  const history = useHistory()

  useEffect(() => {
    // 如果url上有邀请码，记录
    const inviteCode = getQueryString('invite_code')
    if (inviteCode) {
      sessionStorage.setItem('invite_code', inviteCode)
    }
    // 如果有邀请码，带上邀请码
    if (sessionStorage.getItem('invite_code') && !window.location.search.includes('invite_code')) {
      history.push(`${window.location.pathname}?invite_code=${sessionStorage.getItem('invite_code')}`)
    }
    if (login2p.jwt) {
      login2p.getUserDetail()
    }
  }, [login2p.jwt])
  return (
    <Switch>
      <Route
        key={'my'}
        exact
        path={[
          '/',
          '/my',
          '/material/info/:articleID(\\d+)',
          '/material/product',
          '/material/quotation',
          '/search/:keword',
          '/activity/video/:videoID(\\d+)',
          '/activity/info/:activityID(\\d+)',
          '/activity/detail/:activityID(\\d+)',
          '/activity/medium/detail/:activityID(\\d+)',
          '/community/theme/:themeID',
          '/community/author/:authorID',
          // '/indepth/video/:permissionID(\\d+)/:industryID(\\d+)/:videoID(\\d+)',
          '/research/:researchId',
          '/column',
          '/column/view/:columnId',
          '/column/detail/:articleId',
          '/column/editor/:columnId',
          '/column/editor/:columnId/:articleId',
          '/column/modify/:articleId',
          '/column/check/:articleId',
          '/material/license',
          '/material/policy'
        ]}
      >
        <NewIndex />
      </Route>
      <Route key={'login2p'} exact path={'/login2p'}>
        <Login2p />
      </Route>
      <Route key={'login2p/wechat'} path={'/login2p/wechat/'}>
        <Login2pWechatLanding />
      </Route>
      <Route key={'404'}>
        <VmpNotfound />
      </Route>
    </Switch>
  )
}

export default Vmp
