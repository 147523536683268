/**
 *
 * @param name url中需要获取value的key
 * @param url （可选）手动传入url
 */
export const getQueryString = (name: string, url?: string): string | null => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  let r: null | RegExpMatchArray = null
  if (url) {
    r = url.split('?')[1].match(reg)
  } else {
    r = window.location.search.substr(1).match(reg)
  }
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}
