import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { getQueryString } from 'utils/getQueryString'
import { Cookie } from 'utils/cookie'
import { Modal } from 'antd'

import styles from 'styles/CommonGlobal.module.scss'

// const history = useHistory()

const fromString = getQueryString('From')
if (fromString) {
  Cookie.set('From', fromString)
}
const NewAxiosInstanceFunc: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_NEW,
  timeout: 500000
  //withCredentials: true,
})

NewAxiosInstanceFunc.defaults.headers.common['Authorization'] = localStorage.getItem('smtoken') || ''
if (Cookie.get('From') || fromString)
  NewAxiosInstanceFunc.defaults.headers['From'] = Cookie.get('From') || fromString || ''

NewAxiosInstanceFunc.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    if (response.data?.Ret === 406) {
      Modal.confirm({
        title: '登录提示',
        content: '您的登录状态已过期，请重新登录',
        okText: '重新登录',
        className: 'all-dlg-style',
        centered: true,
        onOk: close => {
          localStorage.removeItem('smtoken')
          window.location.href = '/login2p'
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise<AxiosResponse>(() => {}) as Promise<AxiosResponse>
    } else if (response.data?.Ret === 401) {
      Modal.confirm({
        title: '下线提示',
        content:
          '您的账号在另一设备登录，当前设备已被迫下线。若不是您本人操作，请确保未泄露短信验证码或及时修改您的登录密码',
        okText: '重新登录',
        className: 'all-dlg-style',
        centered: true,
        onOk: close => {
          localStorage.removeItem('smtoken')
          window.location.href = '/login2p'
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise<AxiosResponse>(() => {}) as Promise<AxiosResponse>
    }
    return response
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  }
)
export default NewAxiosInstanceFunc
