import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs'

import 'index.css'
import Vmp from 'Vmp'
import reportWebVitals from 'reportWebVitals'
import { MediaProvider } from 'Context/Media/MediaContext'
import { Login2pProvider } from './Login2p/Login2pContext'
import ScrollToTop from 'components/ScrollToTop'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spin />}>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <ConfigProvider
          locale={zhCN}
          theme={{
            token: {
              // Seed Token，影响范围大
              colorPrimary: '#f1a84a',
              borderRadius: 2

              // 派生变量，影响范围小
              // colorBgContainer: '#ffffff'
            }
          }}
        >
          <Router basename={process.env.REACT_APP_BASE_NAME}>
            <Login2pProvider>
              <MediaProvider>
                <Vmp />
              </MediaProvider>
            </Login2pProvider>
          </Router>
        </ConfigProvider>
      </StyleProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
